/* html,body{height:100%;} */
.container-fluid{
  
  padding:0;
  
}
nav{
  color:white;
}
ol{
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
.folderView{
  height: 100%;
  width: 100%;
  background-color:white;
  
}

.navbar-brand{
  margin-right:6rem !important;
}

.App-Login {
  /* background-color: #282c34; */
  /* min-height: 50vh;  */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#282c34;
  height: 90vh;
  padding-top: 10vh;
  
}
.spinner-border {
  margin-left: 50%;
  margin-top: 20%;
}
.toast{
  max-width: 100% !important;
}

td{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr.selected{
  background-color: rgb(221, 221, 221);
}
.table-responsive{
  height:70vh;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
ul li{
  padding:10px;
}
ul li:hover{
  
  background-color: rgb(221, 221, 221);
}
li .dropdown.d-lg-none  {
  display: none;
}
li .dropdown.d-xl-none{
  display:none;
}


@media (max-width: 991.98px) { 
  li:hover .dropdown{
    display: inline-block !important;
  }
}